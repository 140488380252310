import React from 'react';
import "./Footer.css";
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6"; // Import icons
import LogoFooterimg from '../components/Asset/logo.png';

const companyLinks = [
  { heading: "Blog" },
  { heading: "Faq's" },
  { heading: "Privacy policies" },
  { heading: "Contact" },
];

const quickLinks = [
  { heading: "About" },
  { heading: "Community" },
  { heading: "Products" },
  { heading: "Join us" },
];

const socialMediaLinks = [
  { name: "Facebook", icon: <FaFacebook />, url: "https://www.facebook.com" },
  { name: "Twitter", icon: <FaSquareXTwitter />, url: "https://www.twitter.com" },
  { name: "Instagram", icon: <FaInstagram />, url: "https://www.instagram.com" },
  { name: "YouTube", icon: <FaYoutube />, url: "https://www.youtube.com" },
];

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year copywrite

  return (
    <div className='footer-main-container'>
      <div className='footer-container'>
        <div className='logo-footer'>
          <img src={LogoFooterimg} alt='logo' />
        </div>

        <div className='footer-links'>
          <h2>Company</h2>
          {companyLinks.map((link, index) => (
            <h4 key={index}>{link.heading}</h4>
          ))}
        </div>

        <div className='footer-links'>
          <h2>Quick Links</h2>
          {quickLinks.map((link, index) => (
            <h4 key={index}>{link.heading}</h4>
          ))}
        </div>

        <div className='footer-links'>
          <h2>Follow Us</h2>
          <div className='social-media-icons'>
            {socialMediaLinks.map((social, index) => (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                className='social-icon'
                aria-label={social.name}
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className='footer-copywrite'>
        <p>© {currentYear} Angaza Place Limited. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
