import './App.css';
import ContactCard from './components/ContactCard';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      {/* display video background only on homepage */}
      <div className='header-app'>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;